import React from "react"
import LandingPage from "LandingPageMaterial/Layout/LandingPage"
import { useForm } from "react-hook-form"
import dayjs from "dayjs"
import Parallax from "components/material-kit-pro-react/components/Parallax/Parallax.js"
import DateMaterialPickerController from "../components/Core/Controller/DateMaterialPickerController"
import InputController from "../components/Core/Controller/InputController"
import SelectSimpleController from "../components/Core/Controller/SelectSimpleController"
import EmailController from "../components/Core/Controller/EmailController"
import PhoneController from "../components/Core/Controller/PhoneController"
import { Button, Typography, styled, Grid, useTheme } from "@mui/material"
import CardPanel from "../components/Core/Card/CardPanel"
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline"
import SearchIcon from "@mui/icons-material/Search"
import Axios from "axios"
import { getDateForSearch } from "utils/utils"
import { useDialog } from "../context/DialogContext"
import loginPageStyle from "components/material-kit-pro-react/views/loginPageStyle"
import TemplateBlank from "LandingPageMaterial/Layout/TemplateBlank"

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import oceanitoImg from "../../static/Oceanito-Call-Center.png"
import useMediaQuery from '@mui/material/useMediaQuery';

const NewDiv = styled("div")(({ theme }) => {
  const styles = loginPageStyle(theme)
  return { ...styles.container, ...styles.containerTopSeparation }
})

const Experiencia_usuario = () => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme()
  const { handleSubmit, control, ...objForm } = useForm()
  const dialog = useDialog()

  const date = dayjs(new Date())
  const dateEnd = date
  const dateStart = date.add(-1, "month")

  const company = process.env.GATSBY_INSURANCE_COMPANY

  const onSubmit = async (data) => {
    try {
      const params = {
        NOMBRE: data.p_name_one,
        APELLIDO: data.p_second_one,
        FECNAC: getDateForSearch(data.p_start_date),
        TELEFONO: data.p_telefono_tit,
        EMAIL: data.p_email,
        PREGUNTA_1: data.Pregunta_1,
        PREGUNTA_2: data.Pregunta_2,
        PREGUNTA_3: data.Pregunta_3,
        PREGUNTA_4: data.Pregunta_4,
      }
      await Axios.post("/dbo/crm_whatsapp/reg_survey_manager", {
        p_json_info: JSON.stringify(params),
      })
      setOpen(true)
      // dialog({
      //   variant: "info",
      //   catchOnCancel: false,
      //   title: "Alerta",
      //   description: "Se ha enviado su solicitud correctamente",
      //   onSubmit: () => {
      //     if (typeof window !== "undefined") window.location.href = "/"
      //   },
      // })
      // objForm.reset({})
    } catch (error) {
      console.error(error)
    }
  }

  const companyColors = {
    OCEANICA: "#47C0B6",
    PIRAMIDE: "#ED1C24",
  }

  const btnColor = company ? companyColors[company] : "#FFF"

  return (
    <>
      <DialogFinishSurvey open={open} setOpen={setOpen} />

      <TemplateBlank>
        <CardPanel
          titulo="Encuesta Satisfacción Clientes"
          icon={<DriveFileRenameOutlineIcon />}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ width: "100%", padding: "0 15px" }}
          >
            <Grid
              container
              justifyContent="flex-start"
              columnSpacing={{ sx: 0, md: 5 }}
              rowSpacing={{ sx: 0, md: 3 }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  textTransform="capitalize"
                  fontSize="14px"
                  fontWeight={600}
                >
                  Datos Personales
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputController
                  control={control}
                  label="Nombre"
                  name={`p_name_one`}
                  defaultValue={""}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputController
                  control={control}
                  label="Apellido"
                  name={`p_second_one`}
                  defaultValue={""}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DateMaterialPickerController
                  fullWidth
                  control={control}
                  label="Fecha de nacimiento"
                  name="p_start_date"
                  defaultValue={null}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PhoneController
                  control={control}
                  label="Teléfono"
                  name={`p_telefono_tit`}
                  defaultValue={""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <EmailController
                  control={control}
                  label="Correo"
                  name="p_email"
                  required={true}
                  defaultValue={""}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  textTransform="capitalize"
                  fontSize="14px"
                  fontWeight={600}
                >
                  Queremos Conocer
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectSimpleController
                  required={true}
                  control={control}
                  name={`Pregunta_1`}
                  array={[
                    { value: "si", description: "Si" },
                    { value: "no", description: "No" },
                  ]}
                  label={"¿Eres Asegurado de Oceánica de Seguros?"}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectSimpleController
                  required={true}
                  control={control}
                  name={`Pregunta_2`}
                  array={[
                    { value: "si", description: "Si" },
                    { value: "no", description: "No" },
                    { value: "parcialmente", description: "Parcialmente" },
                  ]}
                  label={"¿Este Chat te ayudó a obtener la información deseada?"}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectSimpleController
                  required={true}
                  control={control}
                  name={`Pregunta_3`}
                  array={[
                    { value: "excelente", description: "Excelente" },
                    { value: "bueno", description: "Bueno" },
                    { value: "regular", description: "Regular" },
                    { value: "insuficiente", description: "Insuficiente" },
                  ]}
                  label={"¿Cómo calificas nuestro chat?"}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectSimpleController
                  required={true}
                  control={control}
                  name={`Pregunta_4`}
                  array={[
                    { value: "si", description: "Si" },
                    { value: "no", description: "No" },
                  ]}
                  label={
                    "¿Deseas recibir información de los productos y/o servicios de Oceánica de Seguros?"
                  }
                />
              </Grid>
            </Grid>

            <div
              style={{
                width: "100%",
                marginTop: "3rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ backgroundColor: btnColor }}
                sx={{
                  color: "white",
                  fontSize: "11px",
                  maxWidth: "400px",
                  padding: "10px 0",
                }}
                size="small"
                round
              >
                <Typography color="white.main">Enviar</Typography>
              </Button>
            </div>
          </form>
        </CardPanel>
      </TemplateBlank>
    </>
  )
}

export default Experiencia_usuario

function DialogFinishSurvey({ open, setOpen }) {

  const handleClose = () => {
    if (typeof window !== "undefined") window.location.href = "/"
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle style={{ textAlign: "center", fontWeight: 700 }}>
        {"Gracias por ayudarnos a brindarte la mejor atención, Así de simple."}
      </DialogTitle>
      <DialogContent>
        <div style={{ width: "100%", height: "auto" }}>
          <img
            style={{
              width: "100%",
              height: "auto",
              objectFit: "contain",
            }}
            src={oceanitoImg}
            alt='Oceanito Call Center'
            loading="lazy"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
